import React from 'react'

import logo from './logo.png'
import duck_fumei from './duck_fumei.png'
import duck_ee from './duck_ee.png'

import { SocialIcon } from 'react-social-icons'
import { Provider } from 'wagmi'

import './App.css'

if (document.location.protocol !== "https:" && document.location.href.indexOf("localhost") == -1){
    document.location.replace(window.location.href.replace("http:", "https:"));
}

const App = () => {
    return (
        <Provider>
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <div className="text-block">
                        Dastardly Ducks are quirky, quacky friends who hunt down bad bugs in the infinite rice paddies of the Metaverse. These 
                        badass birbs pick off pests and squirt them out the other end as nourishing nutrients for the soil.
                    </div>

                    <br />

                    <div>
                        <a href="https://twitter.com/DastardlyDucks" className="social-link" target="_new"><SocialIcon fgColor="#FFF" network="twitter" /> Twitter</a>
                        <a href="https://discord.gg/6nzuDZ3aT6" className="social-link" target="_new"><SocialIcon fgColor="#FFF" network="discord" /> Discord</a>
                    </div>

                </header>

                <div className="mint">
                    <h2>HATCH THE BATCH!</h2>

                    <br />

                    <div className="text-block">
                        Drawn by queer, Filipina-American artist FUMEIJI, the sales will directly 
                        benefit a smol duck egg farm and help spread love for the benefits of ducks in regenerative agriculture systems.
                    </div>

                    <h3>HATCHED OUT! BUY SECONDARY?</h3>

                    <div>
                        <a href="https://opensea.io/collection/dastardly-ducks" className="social-link" target="_new">OpenSea</a>
                        <a href="https://looksrare.org/collections/0x5472896E283eBCB13924C659C9dB594aA9Dc05A4" className="social-link" target="_new">Looksrare</a>
                    </div>
                </div>
                

                <div className="team">
                    <h2>CREATORS &amp; FARMERS</h2>

                    <div className="team-row">
                        <div className="team-member">
                            <img src={duck_fumei} className="team-duck" alt="Fumeiji's Duck" />
                            <h3>FUMEIJI.eth</h3>
                            <SocialIcon fgColor="#FFF" url="https://twitter.com/fumeiji" />
                        </div>

                        <div className="team-member">
                            <img src={duck_ee} className="team-duck" alt="Thorne's Duck" />
                            <h3>existentialenso.eth</h3>
                            <SocialIcon fgColor="#FFF" url="https://twitter.com/existentialenso" />
                            &nbsp;
                            <SocialIcon fgColor="#FFF" url="https://github.com/existentialenso" />
                        </div>
                    </div>
                </div>

                <div className="faq">
                    <h2>FAQ</h2>

                    <h3>WHY DUCKS?</h3>

                    <div className="text-block">
                        We raise ducks IRL and wanted to bring them to the Metaverse. Ducks are useful for 
                        regenerative agriculture, helping to take out pest insects and add nutrients to the soil. Plus they're 
                        cute and lay tasty eggs!
                    </div>

                    <h3>HOW ARE THE TRAITS RANDOMIZED?</h3>

                    <div className="text-block">
                        To keep gas prices minimal on an inexpensive NFT, we went with off-chain random generation. 100 were pre-minted with 
                        the smart contract's deployment and put in reserve. 20 of these were minted with pre-determined traits for marketing 
                        purposes and sharing with early supporters. The rest were assembled entirely randomly.
                    </div>
                </div>

                <div className="roadmap">
                    <h2>ROADMAP</h2>

                    <h3>PHASE ONE</h3>

                    <div className="text-block">
                        A maximum of 10,000 unique Dastardly Ducks will be hatchable on this site.
                    </div>

                    <h3>PHASE TWO</h3>

                    <div className="text-block">
                        We will raise several dozen ducklings in real life and 
                        document their growth in video. They will <strong>never</strong> be 
                        used for meat. Only eggs, cuteness, and fertilizing the soil.
                    </div>

                    <h3>AND BEYOND</h3>

                    <div className="text-block">
                        We want to see the Dastardly Duck community grow and evolve. Though 
                        the roadmap ends here, we do not want the road itself to end and will 
                        discuss with Dastardly Duck owners what ideas they would like to see.
                    </div>
                </div>

                <footer>
                    <h3>MORE</h3>
                    <a href="https://twitter.com/smolfarm" target="_new">Smol Farm</a>
                    <a href="https://etherscan.io/address/0x5472896e283ebcb13924c659c9db594aa9dc05a4#code" target="_new">SMART CONTRACT</a>
                    <a href="https://github.com/ExistentialEnso/duck-genesis-generator" target="_new">GENERATOR</a>
                </footer>
            </div>
        </Provider>
    )
}

export default App
